import clsx from 'clsx'
import { useMemo } from 'react'

import { ORDER_HISTORY_TITLE_SHORT } from '@/const'

import { CartPaymentTableProps } from './type'

export const CartPaymentTable = ({
  salonPrice,
  purchasePrice,
}: CartPaymentTableProps) => {
  const salonPriceRows = useMemo(
    () => [
      {
        title: `今回の${ORDER_HISTORY_TITLE_SHORT}`,
        value: `${salonPrice.thisOrder.cost.toLocaleString()}円`,
        isVisible: true,
        isBold: false,
      },
      {
        title: '合計金額※',
        value: `${salonPrice.totalOrder.cost.toLocaleString()}円`,
        isVisible: true,
        isBold: true,
      },
      {
        title: '送料無料まで',
        value: `${(salonPrice.untilFreeShippingCost ?? 0).toLocaleString()}円`,
        isVisible: true, //!!untilFreeShippingCost && untilFreeShippingCost > 0,
      },
    ],
    [salonPrice]
  )

  const purchasePriceRows = useMemo(
    () =>
      purchasePrice
        ? [
            {
              title: `今回の${ORDER_HISTORY_TITLE_SHORT}`,
              value: `${purchasePrice.thisOrder.cost.toLocaleString()}円`,
              isVisible: true,
              isBold: false,
            },
            {
              title: '合計金額※',
              value: `${purchasePrice.totalOrder.cost.toLocaleString()}円`,
              isVisible: true,
              isBold: true,
            },
          ]
        : null,
    [purchasePrice]
  )

  return (
    <div className={clsx('tw-pb-4')}>
      <table className={clsx('tw-w-full', 'tw-border tw-border-gray-300')}>
        <tr className={clsx('tw-border-b tw-border-gray-300')}>
          <td
            className={clsx(
              'tw-w-20 lg:tw-w-auto',
              'tw-text-xs',
              'tw-p-1',
              'tw-bg-primary-50',
              'tw-border-r tw-border-gray-300'
            )}
          >
            サロン価格
          </td>
          <td className={clsx('tw-p-1', 'tw-space-y-1')}>
            {salonPriceRows.map((r, i) => (
              <div
                className={clsx(
                  'tw-flex tw-items-center tw-justify-between',
                  r.isBold ? 'tw-text-sm  tw-font-bold' : 'tw-text-xs'
                )}
                key={`salon-payment-table-row-${i}`}
              >
                <p>{r.title}</p>
                <p>{r.value}</p>
              </div>
            ))}
          </td>
        </tr>
        {purchasePriceRows && (
          <tr>
            <td
              className={clsx(
                'tw-w-20 lg:tw-w-auto',
                'tw-text-xs',
                'tw-p-1',
                'tw-bg-primary-50',
                'tw-border-r tw-border-gray-300'
              )}
            >
              購入価格
            </td>
            <td className={clsx('tw-p-1', 'tw-space-y-1')}>
              {purchasePriceRows.map((r, i) => (
                <div
                  className={clsx(
                    'tw-flex tw-items-center tw-justify-between',
                    r.isBold ? 'tw-text-sm  tw-font-bold' : 'tw-text-xs'
                  )}
                  key={`salon-payment-table-row-${i}`}
                >
                  <p>{r.title}</p>
                  <p>{r.value}</p>
                </div>
              ))}
            </td>
          </tr>
        )}
      </table>
      <p
        className={clsx(
          'tw-ml-auto tw-mt-1',
          'tw-text-xs tw-text-gray-600',
          'tw-text-right'
        )}
      >
        ※同日お届けの他の発注を含む
      </p>
    </div>
  )
}
