import clsx from 'clsx'
import { useMemo, useRef, useState } from 'react'

import { ActionFloatingFooter } from '@/components/layout'
import { Flex } from '@/components/layout/Flex'
import { FloatingFooterSpacer } from '@/components/layout/FloatingFooterSpacer'
import { Button, InputToggle, SlideIn } from '@/components/ui'

import { CartPaymentTable } from './CartPaymentTable'
import { CartPaymentFooterProps } from './type'

export const CartPaymentFooter = ({
  salonPrice,
  purchasePrice,
  disabledOrder,
  errors,
  onSubmit,
}: CartPaymentFooterProps) => {
  const [isOpen, setOpen] = useState(true)
  const ref = useRef<HTMLDivElement>(null)

  const isErrorVisible = errors.desiredDeliveryDate

  const errorMessage = useMemo(() => {
    let message = ''
    if (errors.desiredDeliveryDate) {
      message =
        'お届け可能な納品日が更新されました。ご希望納品日を再選択してください。'
    }
    return message
  }, [errors])

  return (
    <>
      <FloatingFooterSpacer footerElementRef={ref} />
      <ActionFloatingFooter inputRef={ref}>
        <div ref={ref}>
          <div className={clsx('tw-w-full')}>
            {isErrorVisible && (
              <Flex
                gap={2}
                align={'center'}
                className={clsx(
                  'tw-rounded-sm tw-bg-labelRed-50 tw-p-2',
                  'tw-mb-4'
                )}
              >
                <span
                  className={clsx(
                    'tw-text-sm tw-font-semibold',
                    'tw-text-labelRed-500',
                    'tw-flex-shrink-0',
                    'xs:tw-text-xs'
                  )}
                >
                  エラー
                </span>
                <p className={clsx('tw-text-xs', 'tw-whitespace-pre-wrap')}>
                  {errorMessage}
                </p>
              </Flex>
            )}
            <SlideIn isOpen={isOpen}>
              <CartPaymentTable
                salonPrice={salonPrice}
                purchasePrice={purchasePrice}
              />
            </SlideIn>
            <div
              className={clsx(
                'tw-mt-1',
                'tw-flex tw-items-center tw-justify-center'
              )}
            >
              <div
                className={clsx(
                  'tw-flex tw-flex-col tw-items-center',
                  'tw-flex-shrink-0',
                  'tw-px-4'
                )}
              >
                <span className={clsx('tw-block tw-text-sm', 'tw-mb-1')}>
                  合計価格表示
                </span>
                <InputToggle checked={isOpen} onChange={setOpen} />
              </div>
              <Button tabIndex={-1} disabled={disabledOrder} onClick={onSubmit}>
                発注する
              </Button>
            </div>
          </div>
        </div>
      </ActionFloatingFooter>
    </>
  )
}
