import clsx from 'clsx'

import { Footer } from '@/components/app'
import { MaxWidthLayout } from '@/components/layout'
import { Breadcrumb } from '@/components/ui'

import { Header } from '../Header'
import { PageWithHeaderProps } from './type'

export const PageWithHeader = ({
  withSearch = true,
  withMenu = true,
  withLinks = true,
  withFooter = true,
  disableLogoLink = false,
  breadcrumb,
  children,
}: PageWithHeaderProps) => {
  return (
    <>
      <Header
        withSearch={withSearch}
        withMenu={withMenu}
        withLinks={withLinks}
        disableLogoLink={disableLogoLink}
      />
      <div
        className={clsx(
          'tw-flex-1',
          'tw-min-h-[calc(100dvh-3.5rem)] lg:tw-min-h-[calc(100dvh-5rem)]',
          'tw-flex tw-flex-col tw-justify-between'
        )}
      >
        <MaxWidthLayout>
          <div
            className={clsx(
              'tw-relative',
              'tw-overflow-y-auto',
              'focus:tw-outline-none'
            )}
          >
            {breadcrumb && (
              <div className={clsx('tw-border-b tw-border-gray-300')}>
                <MaxWidthLayout>
                  <div className={clsx('tw-px-2 tw-py-2')}>
                    <Breadcrumb items={breadcrumb} />
                  </div>
                </MaxWidthLayout>
              </div>
            )}
            <div>{children}</div>
          </div>
        </MaxWidthLayout>
        {withFooter && <Footer />}
      </div>
    </>
  )
}
