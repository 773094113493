import { SHIPPING_INFORMATION_TITLE_SHORT } from '@/const/label/shippingInformation'

import { APPROVE_TITLE } from './order'

export const ORDER_HISTORY_TITLE = '注文履歴'
export const ORDER_HISTORY_TITLE_SHORT = '注文'

export const ORDER_HISTORY_FIELDS = {
  deliveryDate: 'ご希望納品日',
  orderNo: '発注番号',
  salesOrderNo: '受注番号',
  storeCodes: '店舗',
  shipmentStatus: `${SHIPPING_INFORMATION_TITLE_SHORT}ステータス`,
  approvalStatus: `${APPROVE_TITLE}ステータス`,
}

export const ORDER_HISTORY_ACTIONS = {
  APPROVE: '承認',
  REVOKE_APPROVE: '承認取消',
  CANCEL: 'キャンセル',
}

export const ORDER_HISTORY_BULK_ACTIONS = {
  APPROVE: `一括${ORDER_HISTORY_ACTIONS.APPROVE}`,
  REVOKE_APPROVE: `一括${ORDER_HISTORY_ACTIONS.REVOKE_APPROVE}`,
  CANCEL: `一括${ORDER_HISTORY_ACTIONS.CANCEL}`,
}

export const ORDER_HISTORY_STATUS = {
  UNSHIPPED: `未${SHIPPING_INFORMATION_TITLE_SHORT}`,
  SHIPPING_PREPARATION: `${SHIPPING_INFORMATION_TITLE_SHORT}準備中`,
  SHIPPED: `${SHIPPING_INFORMATION_TITLE_SHORT}済み`,
  ERROR: 'エラー',
}
