import { useController } from 'react-hook-form'

import { FormFieldWrapper, InputSelectMulti } from '@/components/ui'

import { FormFieldSelectMultiProps } from './type'

export const FormFieldSelectMulti = ({
  buttonLabel,
  options,
  disabled,
  label,
  height,
  name,
  control,
  error,
  showError,
}: FormFieldSelectMultiProps) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ name, control })

  const handleBlur = () => {
    onBlur && onBlur()
  }

  return (
    <FormFieldWrapper label={label} error={error} showError={showError}>
      <InputSelectMulti
        label={label}
        buttonLabel={`${buttonLabel}`}
        options={options}
        disabled={disabled}
        selected={options.filter((o) =>
          (value ?? []).some((v: string) => v === o.value)
        )}
        height={height}
        handleChange={(options) =>
          onChange(options.map((option) => option.value))
        }
        handleBlur={handleBlur}
      />
    </FormFieldWrapper>
  )
}
