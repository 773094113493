import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import * as React from 'react'

import type { InputValue } from '@/types'

import { InputSelectLabel, InputSelectOptions } from '../elements'
import { InputSelectSingleButton } from './InputSelectSingleButton'
import { InputSelectSingleOption } from './InputSelectSingleOption'
import { InputSelectSingleProps } from './type'

export const InputSelectSingle = <T extends InputValue = InputValue>({
  ref,
  buttonLabel,
  height = 'lg',
  rounded = 'all',
  selected,
  label,
  name,
  isError,
  className = '',
  handleChange,
  handleFocus,
  handleBlur,
  tabIndex,
  ...props
}: InputSelectSingleProps<T>) => {
  return (
    <Listbox
      as="div"
      horizontal
      value={selected}
      onChange={props.disabled ? () => {} : handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      disabled={props.disabled}
      className={className}
      tabIndex={tabIndex}
    >
      {({ open, disabled }) => (
        <div className={clsx(['tw-relative'])}>
          {label && <InputSelectLabel label={label} />}

          <InputSelectSingleButton
            label={selected ? selected.label : buttonLabel}
            height={height}
            rounded={rounded}
            disabled={disabled}
            tabIndex={tabIndex}
            isError={isError}
          />

          <InputSelectOptions open={open} width={'full'}>
            {props.options.map((option, index) => (
              <InputSelectSingleOption
                key={`input-select-option_${name || ''}_${index}_${
                  option.value
                }`}
                option={option}
              />
            ))}
          </InputSelectOptions>
        </div>
      )}
    </Listbox>
  )
}
